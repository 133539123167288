'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { MobileNavBar } from '@/app/components/MobileNavBar';
import { Navbar } from '@/app/components/NavBar';
import useAuthRedirect from '@/hooks/useAuthRedirectPortal';
import useSegmentAnalytics from '@/hooks/useSegmentAnalytics';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { loggedIn } = useAuthRedirect();
  const pathname = usePathname();
  const { trackEvent } = useSegmentAnalytics();

  useEffect(() => {
    trackEvent('portal_page_viewed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className='flex flex-col items-center w-full min-h-screen'>
      <Navbar loggedIn={loggedIn} />
      <MobileNavBar loggedIn={loggedIn} />
      {children}
    </div>
  );
}
