import { usePathname, useSearchParams } from 'next/navigation';

import analytics from '@/segment';
import getFromLocalStorage from '@/utils/getFromLocalStorage';
import getPageNameFromPath from '@/utils/getPageNameFromPath';

const useSegmentAnalytics = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const trackEvent = (eventName: string, eventProperties?: object) => {
    const source =
      searchParams.get('src') || getFromLocalStorage('attributionSource');
    const attributionId =
      getFromLocalStorage('attributionId') || searchParams.get('gclid');

    const defaultProperties = {
      page_name: getPageNameFromPath(pathname),
      user_source: source || '',
      attribution_id: attributionId || '',
      url: window.location.href,
    };
    const properties = { ...defaultProperties, ...eventProperties };
    return analytics.track(eventName, properties);
  };

  return { trackEvent };
};

export default useSegmentAnalytics;
