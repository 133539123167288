const getPreservedQueryString = (searchParams: URLSearchParams) => {
  const acceptedParams = [
    'email',
    'src',
    'platform',
    'checkout',
    'referrerUID',
    'eventId',
  ];
  let preservedParams = '?';
  searchParams.forEach((value, key) => {
    if (acceptedParams.includes(key) && value) {
      preservedParams += `${key}=${value}&`;
    }
  });

  return preservedParams;
};

export default getPreservedQueryString;
