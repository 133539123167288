import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useState } from 'react';

import { HamburgerIcon } from './icons/HamburgerIcon';
import { SheerLogo } from './SheerLogo';

import {
  FAQ_LANDING_PAGE,
  FEATURES_LANDING_PAGE,
  MEMBERSHIP_LANDING_PAGE,
} from '@/consts/externalLinks';

export const MobileNavBar = ({ loggedIn }: { loggedIn: boolean }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const searchParams = useSearchParams();

  const navigate = (path: string) => {
    router.push(path);
    setIsOpen(false);
  };
  const getTabStyle = (name: string) => {
    return pathname && pathname.includes(name)
      ? 'text-darkPurple font-bold'
      : 'font-medium text-mediumGrey';
  };

  // Disable navigation within mobile webview
  if (searchParams.get('src') === 'native') return null;

  return (
    <div
      className='flex flex-col w-full lg:hidden bg-white z-10 shadow-[0px_2px_10px_0px_rgba(153,153,153,0.15)]'
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className='flex items-center justify-between w-full py-3 pl-4 pr-6 '>
        <SheerLogo />
        <HamburgerIcon />
      </div>

      {/* Menu */}
      {isOpen && (
        <div className='flex flex-col items-end w-full gap-4 px-8 pt-4 pb-8'>
          {loggedIn ? (
            <>
              <button onClick={() => navigate('/home')}>
                <p className={'text-[18px] ' + getTabStyle('home')}>Home</p>
              </button>
              <button onClick={() => navigate('/insurance')}>
                <p className={'text-[18px] ' + getTabStyle('insurance')}>
                  Insurance
                </p>
              </button>
              <button onClick={() => navigate('/messages')}>
                <p className={'text-[18px] ' + getTabStyle('messages')}>
                  Messages
                </p>
              </button>
              <button onClick={() => navigate('/account')}>
                <p className={'text-[18px] ' + getTabStyle('account')}>
                  Account
                </p>
              </button>
            </>
          ) : (
            <>
              <button onClick={() => navigate(MEMBERSHIP_LANDING_PAGE)}>
                <p className={'text-[18px] font-medium text-mediumGrey'}>
                  Membership
                </p>
              </button>
              <button onClick={() => navigate(FEATURES_LANDING_PAGE)}>
                <p className={'text-[18px] font-medium text-mediumGrey'}>
                  Features
                </p>
              </button>
              <button onClick={() => navigate(FAQ_LANDING_PAGE)}>
                <p className={'text-[18px] font-medium text-mediumGrey'}>FAQ</p>
              </button>
              <button onClick={() => navigate('/login')}>
                <p className={'text-[18px] font-medium text-mediumGrey'}>
                  Log In
                </p>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};
