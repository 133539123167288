'use client';

import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { forwardRef } from 'react';

import { SheerLogo } from '@/app/components/SheerLogo';
import {
  FAQ_LANDING_PAGE,
  FEATURES_LANDING_PAGE,
  MEMBERSHIP_LANDING_PAGE,
} from '@/consts/externalLinks';

export const Navbar = ({ loggedIn }: { loggedIn: boolean }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // Disable navigation within mobile webview
  if (searchParams.get('src') === 'native') return null;

  return (
    <nav className='lg:sticky hidden lg:flex w-full justify-between items-center py-4 px-8 bg-white z-10 shadow-[0px_2px_10px_0px_rgba(153,153,153,0.15)] '>
      <Link href='/home'>
        <SheerLogo />
      </Link>
      <div className='flex flex-row justify-end gap-12 grow'>
        {loggedIn ? (
          <LoggedInNavItems pathname={pathname} />
        ) : (
          <LoggedOutNavItems pathname={pathname} />
        )}
      </div>
    </nav>
  );
};

const LoggedInNavItems = ({ pathname }: { pathname: string }) => (
  <>
    <NavItem displayText={'Home'} href={'/home'} path={pathname} />
    <NavItem displayText={'Insurance'} href={'/insurance'} path={pathname} />
    <NavItem displayText={'Messages'} href={'/messages'} path={pathname} />
    <NavItem displayText={'Account'} href={'/account'} path={pathname} />
  </>
);

const LoggedOutNavItems = ({ pathname }: { pathname: string }) => (
  <>
    <NavItem
      displayText={'Membership'}
      href={MEMBERSHIP_LANDING_PAGE}
      path={pathname}
    />
    <NavItem
      displayText={'Features'}
      href={FEATURES_LANDING_PAGE}
      path={pathname}
    />
    <NavItem displayText={'FAQ'} href={FAQ_LANDING_PAGE} path={pathname} />
    <NavItem displayText={'Log In'} href={'/login'} path={pathname} />
  </>
);

const NavItem = forwardRef(function NewNavItem(
  {
    href,
    displayText,
    path,
    close,
  }: {
    href: string;
    displayText: string;
    path: string | null;
    close?: () => void;
  },
  ref?: React.Ref<HTMLAnchorElement>,
) {
  const isTabActive = path && path.includes(href);
  const inactiveTabStyle = 'font-medium text-mediumGrey hover:text-lead';
  const activeTabStyle = 'text-darkPurple font-bold';

  return (
    <Link
      prefetch={false}
      ref={ref as React.Ref<HTMLAnchorElement>}
      className={`flex justify-center pt-1 text-[18px] ${isTabActive ? activeTabStyle : inactiveTabStyle}`}
      href={href}
      onClick={close}
    >
      {displayText}
    </Link>
  );
});
